import React from 'react';
import { Box } from 'rebass';

const BackgroundBubbles = () => (
  <Box>
    <Box
      style={{
        position: 'absolute',
        top: '178px',
        right: '43%',
        width: '119px',
        height: '119px',
        borderRadius: '50%',
        zIndex: '0',
      }}
      bg='#2FB6D2'
    />
    <Box
      style={{
        position: 'absolute',
        top: '200px',
        right: '31%',
        width: '37px',
        height: '37px',
        borderRadius: '50%',
        zIndex: '0',
      }}
      bg='nextonOrange'
    />
    <Box
      style={{
        position: 'absolute',
        bottom: '65px',
        right: '22%',
        width: '320px',
        height: '320px',
        borderRadius: '50%',
        zIndex: '0',
      }}
      bg='#4CC0D9'
    />
    <Box
      style={{
        position: 'absolute',
        bottom: '105px',
        left: '34%',
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        zIndex: '0',
      }}
      bg='nextonOrange'
    />
    <Box
      style={{
        position: 'absolute',
        bottom: '-17px',
        left: '37%',
        width: '35px',
        height: '35px',
        borderRadius: '50%',
        zIndex: '0',
      }}
      bg='nextonYellow'
    />
  </Box>
);

export default BackgroundBubbles;
