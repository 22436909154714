import React from 'react';
import { Box, Text, Link, Image } from 'rebass';
import { socialNetworks } from '../../helpers/constants';

import LinkedInWhite from '../../../assets/images/icons/linkedin-white.svg';
import TwitterWhite from '../../../assets/images/icons/twitter-white.svg';
import FacebookWhite from '../../../assets/images/icons/facebook-white.svg';
import InstagramWhite from '../../../assets/images/icons/instagram-white.svg';

const ContactHero = () => (
  <>
    <Text as='h1' fontSize={['70px', '70px', '90px']} fontWeight='400' color='white'>
      Let's Talk
    </Text>
    <Text as='h2' fontSize={['40px', '40px', '48px']} fontWeight='300' color='white' mb='50px'>
      Let us know how we can help you reach your goals
    </Text>
    <Box>
      <Link href='mailto:contact@nextonlabs.com' target='_blank' rel='noopener'>
        <Text as='span' fontSize={['26px', '26px', '36px']} color='white'>
          contact@nextonlabs.com
        </Text>
      </Link>
    </Box>

    <Box>
      <Box my='11px'>
        <Link aria-label='Visit Nexton LinkedIn' href={socialNetworks.linkedIn} target='_blank' rel='noopener'>
          <Image src={LinkedInWhite} mr='4px' alt='LinkedIn Icon' />
          <Text as='span' fontSize='28px' fontWeight='300' color='white'>
            LinkedIn
          </Text>
        </Link>
      </Box>
      <Box my='11px'>
        <Link aria-label='Visit Nexton Twitter' href={socialNetworks.twitter} target='_blank' rel='noopener'>
          <Image src={TwitterWhite} mr='4px' alt='Twitter Icon' />
          <Text as='span' fontSize='28px' fontWeight='300' color='white'>
            Twitter
          </Text>
        </Link>
      </Box>
      <Box my='11px'>
        <Link aria-label='Visit Nexton Facebook' href={socialNetworks.facebook} target='_blank' rel='noopener'>
          <Image src={FacebookWhite} mr='4px' alt='Facebook Icon' />
          <Text as='span' fontSize='28px' fontWeight='300' color='white'>
            Facebook
          </Text>
        </Link>
      </Box>
      <Box my='11px'>
        <Link aria-label='Visit Nexton Instagram' href={socialNetworks.instagram} target='_blank' rel='noopener'>
          <Image src={InstagramWhite} mr='4px' alt='Instagram Icon' />
          <Text as='span' fontSize='28px' fontWeight='300' color='white'>
            Instagram
          </Text>
        </Link>
      </Box>
    </Box>
  </>
);

export default ContactHero;
