import React from 'react';
import { Box } from 'rebass';
import Layout from '../components/layout/Layout';
import MetaData from '../components/MetaData';
import ContactUs from '../sections/contact/ContactUs';

const ContactPage = () => {
  return (
    <>
      <MetaData title="Let's Talk" description="Let's us know how we can help you reach your goals" />
      <Layout selected='contact' notificationBar='talentBar' layoutTheme='blue'>
        <Box className='full-width' minHeight='calc(100vh - 200px)'>
          <Box className='full-width hero' bg='nextonBlue'>
            <ContactUs />
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default ContactPage;
