import React, { useEffect } from 'react';
import { Box, Flex } from 'rebass';

import ContactHero from '../../components/contact/ContactHero';
import BackgroundBubbles from '../../components/contact/BackgroundBubbles';
import { useHSFormsScriptLoaded } from '../../helpers/hooks';

const ContactUs = () => {
  const HSFormsScriptLoaded = useHSFormsScriptLoaded();

  useEffect(() => {
    if (HSFormsScriptLoaded) {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '14573977',
        formId: 'd9004acc-812f-472a-8082-6f0cba08c5ed',
        target: '#contact-page-form',
      });
    }
  }, [HSFormsScriptLoaded]);

  return (
    <Box className='body-layout' style={{ position: 'relative' }}>
      <Flex flexWrap='wrap' justifyContent='center' alignItems='flex-start' pt='220px' pb='50px'>
        <Box width={[1, 1, 1 / 2]} style={{ zIndex: '1' }} px={['0px', '20px']} mb={['50px', '50px', '0px']}>
          <ContactHero />
        </Box>
        <Box width={[1, 1, 1 / 2]} style={{ zIndex: '1' }} px={['0px', '20px']} minHeight='721px'>
          <div id='contact-page-form' className='nexton-form-with-recaptcha' />
        </Box>
      </Flex>
      <BackgroundBubbles />
    </Box>
  );
};

export default ContactUs;
